import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <div className="notFound container-lg service-bg">
        <div className="columns main-ctn">
            <div className="column is-12 text-center">
              <h1 className="title ">Désolé!</h1>
              <p className="strong">Cette page n'existe pas</p>
            </div>
        </div>
    </div>
    <div className="spacer-red triangle-red-left is-relative"></div>
  </Layout>
)

export default NotFoundPage
